<template>
    <div>
        <div v-if="hasTransactions">
            <v-data-table
                class="compact"
                v-bind="tableSettings"
                :headers="tableHeaders"
                :items="tableItems"
                :item-class="rowBackground"
                @click:row="rowClicked"
            />
            <div class="d-flex justify-end align-center vat-info">
                <span
                    ><v-icon small>mdi-information</v-icon>Amounts do not
                    include Tax</span
                >
            </div>
            <div class="d-flex justify-end">
                <table class="totals">
                    <tr>
                        <th>Total Amount:</th>
                        <td>{{ totalAmount }}</td>
                    </tr>
                    <tr>
                        <th>Total VAT:</th>
                        <td>{{ totalVat }}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div v-else class="error--text mb-3">No transactions.</div>

        <v-btn
            v-if="editable"
            color="primary"
            block
            class="mr-n3"
            @click="newClicked"
            ><v-icon left>mdi-plus</v-icon> Add Transaction</v-btn
        >
    </div>
</template>
<script>
import { generateHeaders } from "@/util/tableUtil";
import tableSettings from "@/defaults/table";

const NewTransactionForm = () => import("./NewTransactionForm");
const EditForm = () => import("./EditTransactionForm");

export default {
    props: {
        payment: {
            required: true,
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        hasTransactions: function () {
            return (
                this.payment.aspectData.paymentRequest.transactionItems.length >
                0
            );
        },
        tableSettings: function () {
            return tableSettings;
        },
        tableItems: function () {
            return this.payment.aspectData.paymentRequest.transactionItems.map(
                (item) => ({
                    item: item.itemCode,
                    description: item.itemDescription,
                    quantity: item.itemQuantity,
                    unitOfMesaure: item.itemUnitOfMeasure,
                    price: item.itemPrice.toFixed(2),
                    totalAmount: item.amount.toFixed(2),
                    id: item.id,
                })
            );
        },
        tableHeaders: function () {
            return generateHeaders(this.tableItems);
        },
        totalAmount: function () {
            const totalAmount =
                this.payment.aspectData.paymentRequest.transactionItems.reduce(
                    (total, item) => total + item.amount,
                    0
                );

            return totalAmount.toFixed(2);
        },
        totalVat: function () {
            const totalVat =
                this.payment.aspectData.paymentRequest.transactionItems.reduce(
                    (total, item) => total + item.vatAmount,
                    0
                );

            return totalVat.toFixed(2);
        },
    },
    methods: {
        newClicked: function () {
            this.$coreUi.dialog(
                NewTransactionForm,
                {
                    payment: this.payment,
                },
                {
                    closing: (result) => {
                        if (result) {
                            this.$emit("update");
                        }
                    },
                }
            );
        },
        rowClicked: function (item) {
            if (this.editable) {
                this.$coreUi.dialog(
                    EditForm,
                    {
                        payment: this.payment,
                        transactionId: item.id,
                    },
                    {
                        closing: (result) => {
                            if (result) {
                                this.$emit("update");
                            }
                        },
                    }
                );
            }
        },
        rowBackground: function (item) {
            return item.totalAmount === "0.00" ? "error--text" : "";
        },
    },
    emits: ["update"],
};
</script>
<style lang="scss" scoped>
.vat-info {
    font-size: 0.875rem !important;
}

.totals {
    font-size: 0.875rem !important;

    & th {
        text-align: right;
        padding-right: 20px;
    }
}
</style>